@import "~bootstrap/scss/functions";

// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

//
// Color system
//

$blue:    #adcbec;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #b9b9b9;
$gray-600: #a5b6b7;
$gray-700: #555555;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;

$beige1:     #f7bfa6;
$beige2:     #dcaa89;
$beige3:     #dcb98f;
$beige4:     #d7ac79;
$beige5:     #d5af91;
$pink1:      #d0b8bf;
$pink2:      #e3a6c0;
$pink3:      #797979;
$pink4:      #c95462;
$pink5:      #dda4ad;
$pink6:      #f6eaed;
$pink7:      #eacbc2;

$primary:       #555;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         #f3f3f3;
$dark:          $gray-800 !default;

$theme-colors: (
  "white":      #ffffff,
  "black":      #000000,
  "beige1":     #f7bfa6,
  "beige2":     #dcaa89,
  "beige3":     #dcb98f,
  "beige4":     #d7ac79,
  "beige5":     #d5af91,
  "pink1":      #d0b8bf,
  "pink2":      #e3a6c0,
  "pink3":      #797979,
  "pink4":      #c95462,
  "pink5":      #dda4ad,
  "pink6":      #f6eaed,
  "pink7":      #eacbc2,
  "gray-500":   #b9b9b9,
  "gray-600":   #a5b6b7,
  "gray-700":   #555555,
  "blue":   #adcbec
);

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900 !default;
$yiq-text-light:            $white !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:              true !default;
$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;

$spacer: 1rem !default;
$spacers: (
  rv : 5px,
  r1  : 10px,
  r1v  : 15px,
  r2  : 20px,
  r2v  : 25px,
  r3  : 30px,
  r3v  : 35px,
  r4  : 40px,
  r4v  : 45px,
  r5  : 50px,
  r5v  : 55px,
  r6  : 60px,
  r6v  : 65px,
  r7  : 70px,
  r7v  : 75px,
  r8  : 80px,
  r8v  : 85px,
  r9  : 90px,
  r9v  : 95px,
  r10 : 100px,
  r11 : 110px,
  r12 : 120px,
  r13 : 130px,
  r14 : 140px,
  r15 : 150px,
  r16 : 160px,
  r17 : 170px,
  r18 : 180px,
  r19 : 190px,
  r20 : 200px,
  r25 : 250px,
  r30 : 300px,
  r35 : 350px,
  r40 : 400px,
  r45 : 450px,
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (

) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white;
$body-color:                $gray-900;

// Links
//
// Style anchor elements.

$link-color:                theme-color("primary") !default;
$link-decoration:           none !default;
//$link-hover-color:          darken($link-color, 15%) !default;
$link-hover-decoration:     underline !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1180px
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;

$border-width:                1px !default;
$border-color:                $gray-300 !default;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;

$component-active-color:      $white !default;
$component-active-bg:         theme-color("primary") !default;

$caret-width:                 .3em !default;

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-Poppins:        'Poppins', sans-serif;
$font-family-Didot:          'Didot', serif;
$font-family-GothamMedium:   'Gotham-Medium', sans-serif;
$font-family-GothamBook:     'Gotham-Book', sans-serif;
$font-family-base:            $font-family-Poppins;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * .875) !default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;

$headings-margin-bottom:      ($spacer / 2) !default;
$headings-font-family:        inherit !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              inherit !default;

$display1-size:               6rem !default;
$display2-size:               5.5rem !default;
$display3-size:               4.5rem !default;
$display4-size:               3.5rem !default;

$display1-weight:             300 !default;
$display2-weight:             300 !default;
$display3-weight:             300 !default;
$display4-weight:             300 !default;
$display-line-height:         $headings-line-height !default;

$lead-font-size:              ($font-size-base * 1.25) !default;
$lead-font-weight:            300 !default;

$small-font-size:             80% !default;

$text-muted:                  $gray-600 !default;

$blockquote-small-color:      $gray-600 !default;
$blockquote-font-size:        ($font-size-base * 1.25) !default;

$hr-border-color:             rgba($black, .1) !default;
$hr-border-width:             $border-width !default;

$mark-padding:                .2em !default;

$dt-font-weight:              $font-weight-bold !default;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) !default;
$nested-kbd-font-weight:      $font-weight-bold !default;

$list-inline-padding:         .5rem !default;

$mark-bg:                     #fcf8e3 !default;

$hr-margin-y:                 $spacer !default;

// Page header
$page-header-height : 50px;
$page-header-height-lg : 110px;