.main-content {
  margin-top: $page-header-height + 73px ;
  transition: margin-top .3s ease;
  @include media-breakpoint-up(lg){
    margin-top: $page-header-height-lg + 227px;
  }
  &.is-top-margin {
    margin-top: 80px;
    transition: all .3s ease;
  }
}

.offers-box {
  a, .position-relative {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
}

.scroll-to {
  position: absolute;
  bottom: 20px;
  left: calc(50% - 15px);
  img {
    width: 26px;
    opacity: .35;
    transform: rotate(90deg);
    transition: opacity .3s ease;
    &:hover {
      opacity: .9;
    }
  }
}
#googleMap {
  height: 300px;
}

.logo-cnt {
  .logo-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    border: 1px solid #f4e6ea;
    margin-bottom: 30px;
    img {
      width: 65%;
      height: auto;
      @include media-breakpoint-up(lg) {
        width: 90%;
      }
    }
    img.img-special {
      width: 50%;
      height: auto;
      @include media-breakpoint-up(md) {
        width: 55%;
      }
      @include media-breakpoint-up(lg) {
        width: 70%;
      }
    }
  }
}
.circle-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 130px;
  h2 {
    margin: 0
  }
  p {
    margin: 0;
  }
}
.circle-bg {
  position: absolute;
  content: '';
  top: 0;
  left: 50%;
  bottom: 0;
  right: 0;
  margin-left: -65px;
  background: $white;
  width: 130px;
  height: 130px;
  border-radius: 50%;
}
.box-cnt {
  border: 1px solid #f1dce1;
  text-align: center;
  margin-bottom:  30px;
  &__header,
  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 22px;
    padding: 0 10px;
  }
  &__header {
    height: 65px;
    background: $pink6;
    font-family: $font-family-Didot;
    font-size: 18px;
    text-transform: uppercase;
    color: #363636;
  }
  &__text {
    height: 75px;
    font-family: $font-family-GothamBook;
    font-size: 16px;
    color: #4a4a4a;
  }
}

.box-contact {
  .font-gotham-medium {
    font-size: 18px;
  }
}
