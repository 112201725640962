.card {
  &-img-wrap {
    overflow:hidden;
    img {
      transition: $transition-base;
    }
  }
  &-blocklink {
    color: inherit;
    &:hover {
      color:inherit;
      text-decoration:none;
      .card-img-wrap img {
        transform:scale(1.1);
      }
    }
  }
}