.frame {
  $this : &;
  &__head {
    //margin-bottom:3rem;
    &:last-child {
      margin-bottom:0;
    }
  }
  &__head_icon-left {
    display:flex;
    &.text-center {
      justify-content:center;
    }
    #{$this}__icon {
      margin-right: 1rem;
    }
  }
  &__icon {
    //margin-bottom:3rem;
    margin-bottom:0;
  }
  &__headings {

  }
  &__header {
    margin:0;
  }
  &__subheader {
    margin:0;
  }
}