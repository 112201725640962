.mainnav {
  $this : &;
  position:relative;
  z-index:1000;
  @include media-breakpoint-up(lg){
    width:100%;
    #{$this}__toggler {
      display:none;
    }
  }
}
//mainnav example component
.navlist {
  $this : &;
  list-style:none;
  padding:0;
  margin:0;
  @include media-breakpoint-down(md){
    width:100%;
    .mainnav__toggler {
      border:none;
      position: absolute;
      width: 100%;
      background: transparent;
      height: 54px;
      text-align: right;
      svg {
        font-size: 12px;
        transform: rotate(90deg) !important;
        transition: all .3s ease;
      }
      &.is-active svg {
        transform: rotate(270deg) !important;
      }
    }
    &__item {
      display:flex;
      flex-wrap:wrap;
    }
    &__link {
      padding:$spacer $spacer/2;
      display:block;
      flex-grow:1;
      background:$pink3;
      color: $white;
      text-align: center;
    }
    & & &__link {
      background:$white;
      color:$body-color;
    }
    &_level-2 > &__item > &__link {
      padding-left:$spacer;
    }
  }
  @include media-breakpoint-up(lg){
    &__item {
      position:relative;
      &:hover > #{$this} {
        transition: transform .2s 50ms ease, opacity .2s 50ms ease, visibility 0s linear;
        opacity:1;
        visibility: visible;
        transform: translateY(0);
      }
    }
    & & {
      position:absolute;
      background:$light;
      opacity:0;
      visibility: hidden;
      display:block;
      transform: translateY(-5px);
      transition: transform .2s .2s ease, opacity .2s .2s ease, visibility 0s .4s linear;
    }
    &_level-0 {
      display:flex;
      justify-content:space-between;
      width:100%;
    }
    &_level-0 &__link {
      display:block;
      padding:0;
      font-family: $font-family-Poppins;
      font-size: 13px;
      font-weight: 300;
      color: $gray-700;
      transition: all .3s ease;
      &:hover {
        color: $pink4;
        text-decoration: none;
      }
    }
    &_level-1 {
      top:100%;
      left:0;
    }
    &_level-1 &__link {
      background:$primary;
      width:100%;
      color:$light;
      text-align:left;
      white-space:nowrap;
    }
    &_level-2 {
      top:0;
      left:100%;
    }
    &_level-2 &__link {
      background:$secondary;
    }
  }
}