.wrapper {
  position:relative;
  &__bg-image {
    position:absolute;
    top:0;
    left:0;
    object-fit:cover;
    width:100%;
    height:100%;
  }
  &__content {
    position:relative;
  }
}