.icon-box {
  $this : &;
  text-align: center;
  img,.icon {
    height: 110px;
    width:100%;
    transform: scale(.95);
    object-fit:contain;
    transition: all .3s ease;
    display:inline-block;
    @include media-breakpoint-up(lg) {
      height: 140px;
    }
  }
  &__header {
    letter-spacing: normal;
    transition: all .3s ease;
  }
  &-link {
    &:hover {
      text-decoration:none;
      img,.icon {
        transform: scale(1.05);
      }
      #{$this}__header {
        letter-spacing: 1.1px;
        text-decoration:none;
      }
    }
  }
}