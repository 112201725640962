@each $i in cover contain {
  .object-fit-#{$i}{
    object-fit: $i;
  }
}
@each $i in 25 50 75 100 {
  .opacity-#{$i}{
    opacity: $i/100;
  }
}


/** Min height with flexbox, vertical center and breakpoint dependent */
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $val in 25 50 75 100 {
      .vh#{$infix}-#{$val} {
        min-height:$val * 1vh;
        display:flex;
        justify-content:center;
        flex-direction: column;
      }
    }
  }
}