.teaser {
  position:relative;
  .owl-dots {
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    z-index:1;
    height:map_get($spacers,5);
    line-height:1;
    display:flex;
    align-items:center;
    justify-content:center;
  }
  button.owl-dot {
    display:inline-block;
    border:1px solid $primary;
    width:12px;
    height:12px;
    border-radius:50%;
    margin:0 2px;
    &.active {
      background:$primary;
    }
  }
}