.halfpic {
  position:relative;
  overflow:hidden;
  // Padding between pic and content
  $sidepad : map-get($spacers,5);
  &__figure {
    margin:0;
    @include media-breakpoint-up(md){
      position:absolute;
      top:0;
      left:50%;
      right:0;
      bottom:0;
    }
  }
  &__image {
    width:100%;
    @include media-breakpoint-up(md){
      object-fit:cover;
      object-position:center left;
      max-width:none;
      position:absolute;
      height:100%;
    }
  }
  &_pic-left & {
    &__content {
      @include media-breakpoint-up(md){
        padding-right: $sidepad;
      }
    }
  }
  &_pic-right & {
    &__figure {
      left:0;
      right:50%;
    }
    &__image {
      object-position:center right;
    }
    &__row {
      justify-content:flex-end;
    }
    &__content {
      @include media-breakpoint-up(md){
        padding-left: $sidepad;
      }
    }
  }
}