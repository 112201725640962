@function rem($px,$scale: 16px){
  @return ($px/$scale) * 1rem;
}
// Issues with max() function - https://github.com/sass/sass/issues/2378
@function max($numbers...){
  @return m#{a}x(#{$numbers});
}
@function min($numbers...) {
  @return m#{i}n(#{$numbers});
}
