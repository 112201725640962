.l-header {
  $this : &;
  position:relative;
  z-index:$zindex-sticky;
  &_overlay {
    height:0;
  }
  &__above {
    transition:all .3s ease;
    overflow:hidden;
    height:1.6em;
    padding:0;
  }
  &__inner {
    transition: all .3s ease;
    min-height: $page-header-height;
    @include media-breakpoint-up(lg){
      min-height: $page-header-height-lg;
    }
  }
  .navbar {
    background: $white;
    transition: all .3s ease;
  }
  .navbar-brand img {
    width: 240px;
    height: auto;
    transition: all .3s ease;
    @include media-breakpoint-up(md) {
      width: 265px;
    }
  }
  &.js-is-sticky {
    #{$this}__above {
      height:0;
    }
    #{$this}__inner {
      min-height: $page-header-height;
    }
  }
  &.js-is-sticky {
    .navbar {
      padding-top:0;
      padding-bottom:10px;
      background: rgba($light, 0.5);
      transition: all .3s ease;
    }
    .navbar-brand {
      padding: 5px 0;
      transition: all .3s ease;
      img {
        width: 100px;
        transition: all .3s ease;
      }
    }
    &:hover .navbar {
      background: $light;
    }
  }
}

