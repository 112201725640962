/* Styles taken from Font Awesome 5 SVG solution */

svg:not(:root).icon {
  overflow: visible; 
}

.icon {
  $this : &;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  width:1em;
  vertical-align: -.125em;
  fill:currentColor;
  &-pull-left {
    margin-right: .3em;
    width: auto; }
  &-pull-right {
    margin-left: .3em;
    width: auto; }
  &-border {
    height: 1.5em; }
  &-li {
    width: 2em; }
  &-fw {
    width: 1.25em; }
  &-lg {
    font-size: 1.33333em;
    line-height: 0.75em;
    vertical-align: -.0667em; }
  &-xs {
    font-size: .75em; }
  &-sm {
    font-size: .875em; }
  &-1x {
    font-size: 1em; }
  &-2x {
    font-size: 2em; }
  &-3x {
    font-size: 3em; }
  &-4x {
    font-size: 4em; }
  &-5x {
    font-size: 5em; }
  &-6x {
    font-size: 6em; }
  &-7x {
    font-size: 7em; }
  &-8x {
    font-size: 8em; }
  &-9x {
    font-size: 9em; }
  &-10x {
    font-size: 10em; }
  &-fw {
    text-align: center;
    width: 1.25em; }
  &-ul {
    list-style-type: none;
    margin-left: 2.5em;
    padding-left: 0; }
  &-ul > li {
    position: relative; }
  &-li {
    left: -2em;
    position: absolute;
    text-align: center;
    width: 2em;
    line-height: inherit; }
  &-border {
    border: solid 0.08em #eee;
    border-radius: .1em;
    padding: .2em .25em .15em; }
  &-pull-left {
    float: left; }
  &-pull-right {
    float: right; }
  &-spin {
    animation: fa-spin 2s infinite linear; }
  &-pulse {
    animation: fa-spin 1s infinite steps(8); }
  &-rotate-90 {
    transform: rotate(90deg); }
  &-rotate-180 {
    transform: rotate(180deg); }
  &-rotate-270 {
    transform: rotate(270deg); }
  &-flip-horizontal {
    transform: scale(-1, 1); }
  &-flip-vertical {
    transform: scale(1, -1); }
  &-flip-horizontal#{$this}-flip-vertical {
    transform: scale(-1, -1); }
}
@-webkit-keyframes icon-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes icon-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
