.navbar {
  padding: 0 10px;
  transition: all .3s ease;
  @include media-breakpoint-up(lg) {
    padding: 0 0 40px;
  }
  .container {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }
}
.navbar-brand {
  margin: 0;
  padding: 30px 0;
  @include media-breakpoint-up(lg) {
    padding: 45px 0;
  }
  .logo-sm {
    display: block;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  .logo-md {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}
.navbar-expand-lg .navbar-collapse {
  width: 100%;
}
.navbar-light .navbar-toggler {
  width: 40px;
  height: 40px;
  border-color: rgba(0, 0, 0, 0);
  color: $black;
  background: transparent;
  margin: 0;
  padding: 7px;
  cursor: pointer;
}
.hamburger {
  position: relative;
  display: block;
  width: 46px;
  height: 41px;
  z-index: 2;
}
.hamburger {
  position: relative;
  display: block;
  width: 46px;
  height: 41px;
  z-index: 2;
}
.hamburger__inner {
  display: block;
  width: 26px;
  height: 3px;
  margin-top: 0;
  background: $pink3;
  transition: all 0.3s ease-in-out;
  &::before, &::after {
    display: block;
    position: absolute;
    content: "";
    z-index: -1;
    width: 26px;
    height: 3px;
    background: $pink3;
    transition: all 0.3s ease-in-out;
  }
  &::before {
    top: 10px;
  }
  &::after {
    top: 26px;
  }
}
.hamburger--open {
  .hamburger__inner {
    background: none;

    &::before, &::after {
      top: 18px;
      background: $pink3;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }
}