.go-top {
  $this : &;
  opacity:0;
  transition:$transition-base;
  position:fixed;
  bottom: $grid-gutter-width/2;
  right: $grid-gutter-width/2;
  padding:0;
  width: 3rem;
  height: 3rem;
  background:$white;
  box-shadow:$box-shadow-sm;
  transform:translateY(-10px);
  font-size:12px;
  border:1px solid $gray-400;
  cursor:pointer;
  pointer-events:none;
  outline:1px solid transparent !important;
  &:active {
    transform:translateY(2px);
    transition:none;
  }
  @if $enable-rounded {
    border-radius:$border-radius;
  }
  &_visible {
    opacity:1;
    transform:translateY(0);
    pointer-events:all;
  }
  &__icon {
    display:inline-block;
    &:empty {
      width:1em;
      height:1em;
      border-top:2px solid $gray-400;
      border-right:2px solid $gray-400;
      transform:rotate(-45deg);
      margin-bottom:-0.25em;
    }
  }
}