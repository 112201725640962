body {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  color: $gray-700;
}
.container {
  overflow: hidden !important;
}

@font-face {
  font-family: 'Didot';
  src: url('../Font/Didot-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham-Book';
  src: url('../Font/Gotham-Book.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham-Medium';
  src: url('../Font/Gotham-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

.z-index--1,
.z-index-0,
.z-index-1,
.z-index-2 {
  position: relative;
}
.z-index--1 {
  z-index: -1;
}
.z-index-0 {
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.shadow {
  position: relative;
  transition: all .3s ease;
  &:hover {
    box-shadow: 0 6px 15px 0 rgba(32,34,52,0.15) !important;
  }
}
.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
img {
  max-width:100%;
  height:auto;
}
figure {
  margin:0;
}
figure.hover-effect {
  position: relative;
  margin:0;
  background: linear-gradient(183.68deg, rgba(20,28,110,0) 0%, rgba(20,28,110,0.95) 100%);
  img {
    opacity: 1;
    transition: .3s ease-in-out;
    margin: 0;
  }
  &:hover img {
    opacity: .35;
  }
}

.h-row {
  margin-bottom: 15px;
  [class^='col-'] {
    height: 380px;
    div {
      height: 100%;
    }
  }
}

.font-gotham-medium {
  font-family: $font-family-GothamMedium;
}
.font-gotham-book {
  font-family: $font-family-GothamBook;
}
.font-didot {
  font-family: $font-family-Didot;
}
.font-poppins {
  font-family: $font-family-Poppins;
}

/*styleguide*/
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, h6 {
  font-weight: normal;
  margin: 0;
}
h1 {
  font-size: 46px;
  line-height: (57/46);
  @include media-breakpoint-up(lg) {
    font-size: 55px;
    line-height: 1;
  }
}
h2 {
  font-size: 40px;
  line-height: (44/40);
  @include media-breakpoint-up(lg) {
    font-size: 48px;
  }
}
h3 {
  font-size: 30px;
  line-height: (34/30);
  @include media-breakpoint-up(lg) {
    font-size: 40px;
  }
}
h4 {
  font-size: 28px;
  line-height: (34/28);
}
h5 {
  font-size: 24px;
  line-height: (26/24);
}
h6 {
  font-size: 20px;
  line-height: (24/20);
}

p {
  font-size: 16px;
  line-height: (18/16);
  margin: 0;
}
ol {
  li {
    margin-bottom: 15px;
  }
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul.list-dot,
ul.list-square {
  padding: 0;
  li {
    position: relative;
    text-align: left;
    padding-left: 18px;
    font-weight: normal;
    a {
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      color: inherit;
      text-decoration: none;
      transition: all .3s ease;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
ul.list-dot,
ul.list-square {
  li:before {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 0;
    width: 6px;
    height: 6px;
    background: $primary;
  }
}
ul.list-dot {
  li:before {
    border-radius: 50%;
  }
}
.img-h-300 {
  height: 300px;
}

.container-no-margin {

}

